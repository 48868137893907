import React, { Fragment } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Page from '@components/Page';
import ProjectFunding from '@components/ProjectFunding';
import { css } from '@emotion/react';
import { getPageElements } from '@lib/utils';
import { graphql } from 'gatsby';

export default function FundingTemplate({
  data: { page },
  pageContext: { language, refs, layouts, configuration }
}) {
  const { alpha_title, alpha_body, szybka_title, szybka_body, granty_title, granty_body, platformy_title, platformy_body, ncbir, poir, popw } = getPageElements(page);
  return (
    <Page>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10 col-md-12">
            <ProjectFunding
              title={szybka_title}
              body={szybka_body}
              banner={ncbir}
            />
            <hr />
            <ProjectFunding
              title={granty_title}
              body={granty_body}
              banner={poir}
            />
            <hr />
            <ProjectFunding
              title={alpha_title}
              body={alpha_body}
              banner={ncbir}
            />
            <hr />
            <ProjectFunding
              title={platformy_title}
              body={platformy_body}
              banner={popw}
            />
          </div>
        </div>
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;