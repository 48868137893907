import React, { PureComponent } from 'react';
import GatsbyImage from 'gatsby-image';

export default class ProjectFunding extends PureComponent {
  render() {
    const { title, banner, body } = this.props;
    return (
      <div className="mb-5">
        <h2 className="text-center">{title}</h2>
        <GatsbyImage fluid={banner.localFile.sharp.fluid} />
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    );
  }
}